import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import TextInput from '@components/requestForms/inputs/text.inputs'
import RadioInput from '@components/requestForms/inputs/radio.input'
import TextAreaInput from '@components/requestForms/inputs/textarea.inputs'
import DateFilterInput from './inputs/datefilter.input'

const ObligationsAndStandardsForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		radioSelectionYesNo,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection
	} = requestForm()

	const maxCharacters: number = 10

	const [requestAboutInput, setRequestAboutInput] = useState<RequestInputs>({
		name: 'requestAbout',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_obligationsAndStandards_requestAbout_label,
		labelKey: 'request_form_obligationsAndStandards_requestAbout_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})
	const [residenceBeenBuiltInput, setResidenceBeenBuiltInput] =
		useState<RequestInputs>({
			name: 'residenceBeenBuilt',
			value: '',
			label:
				pageAssets?.request_form_obligationsAndStandards_residenceBeenBuilt_label,
			labelKey: 'request_form_obligationsAndStandards_residenceBeenBuilt_label',
			required: false
		})
	const [livedInThisResidenceInput, setLivedInThisResidenceInput] =
		useState<RequestInputs>({
			name: 'livedInThisResidence',
			value: '',
			label:
				pageAssets?.request_form_obligationsAndStandards_livedInThisResidence_label,
			labelKey:
				'request_form_obligationsAndStandards_livedInThisResidence_label',
			required: false
		})
	const [certificateLocationInput, setCertificateLocationInput] =
		useState<RequestInputs>({
			name: 'certificateLocation',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_obligationsAndStandards_certificateLocation_label,
			labelKey:
				'request_form_obligationsAndStandards_certificateLocation_label',
			required: false
		})
	const [plannedWorksAndCostInput, setPlannedWorksAndCostInput] =
		useState<RequestInputs>({
			name: 'plannedWorksAndCost',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_obligationsAndStandards_plannedWorksAndCost_label,
			labelKey:
				'request_form_obligationsAndStandards_plannedWorksAndCost_label',
			required: false
		})

	const requestAboutList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_select_obligationsAndStandards_enlargement,
			value:
				pageAssets?.request_form_select_obligationsAndStandards_enlargement,
			key: 'request_form_select_obligationsAndStandards_enlargement'
		},
		{
			label:
				pageAssets?.request_form_select_obligationsAndStandards_renovations,
			value:
				pageAssets?.request_form_select_obligationsAndStandards_renovations,
			key: 'request_form_select_obligationsAndStandards_renovations'
		},
		{
			label: pageAssets?.request_form_select_obligationsAndStandards_other,
			value: pageAssets?.request_form_select_obligationsAndStandards_other,
			key: 'request_form_select_obligationsAndStandards_other'
		}
	]

	const onSelectRequestAboutInput = (value: string, valueKey: string) => {
		setRequestAboutInput({ ...requestAboutInput, value, valueKey })
		setCertificateLocationInput({
			...certificateLocationInput,
			value: '',
			valueKey: ''
		})
		setPlannedWorksAndCostInput({ ...plannedWorksAndCostInput, value: '' })
		onFixError(UpdateRequestInputsErrorsArray(errors, requestAboutInput.name))
	}

	const onSelectResidenceBeenBuiltInput = (value: string) => {
		setResidenceBeenBuiltInput({ ...residenceBeenBuiltInput, value })
	}

	const onSelectLivedInThisResidenceInput = (value: string) => {
		setLivedInThisResidenceInput({ ...livedInThisResidenceInput, value })
	}

	const onSelectCertificateLocationInput = (
		value: string,
		valueKey: string
	) => {
		setCertificateLocationInput({
			...certificateLocationInput,
			value,
			valueKey
		})
	}

	const onSelectPlannedWorksAndCostInput = (value: string) => {
		setPlannedWorksAndCostInput({ ...plannedWorksAndCostInput, value })
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, requestAboutInput))
	}, [requestAboutInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, residenceBeenBuiltInput))
	}, [residenceBeenBuiltInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, livedInThisResidenceInput))
	}, [livedInThisResidenceInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, certificateLocationInput))
	}, [certificateLocationInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, plannedWorksAndCostInput))
	}, [plannedWorksAndCostInput])

	return (
		<>
			<SelectInput
				id={requestAboutInput.name}
				label={requestAboutInput.label}
				value={requestAboutInput.value}
				list={requestAboutList}
				onChange={onSelectRequestAboutInput}
				required
				ref={requestAboutInput.ref as RefObject<HTMLSelectElement>}
			/>
			<DateFilterInput
				id={residenceBeenBuiltInput.name}
				label={residenceBeenBuiltInput.label}
				value={residenceBeenBuiltInput.value}
				onChange={onSelectResidenceBeenBuiltInput}
				dateFormat="dd/MM/yyyy"
			/>

			<DateFilterInput
				id={livedInThisResidenceInput.name}
				label={livedInThisResidenceInput.label}
				value={livedInThisResidenceInput.value}
				onChange={onSelectLivedInThisResidenceInput}
				dateFormat="dd/MM/yyyy"
			/>
			{/*
			<TextInput
				type="date"
				id={residenceBeenBuiltInput.name}
				label={residenceBeenBuiltInput.label}
				value={residenceBeenBuiltInput.value}
				maxCharacters={maxCharacters}
				onChange={onSelectResidenceBeenBuiltInput}
			/>

			<TextInput
				type="date"
				id={livedInThisResidenceInput.name}
				label={livedInThisResidenceInput.label}
				value={livedInThisResidenceInput.value}
				maxCharacters={maxCharacters}
				onChange={onSelectLivedInThisResidenceInput}
			/>
			 */}

			{requestAboutInput.value ===
				pageAssets?.request_form_select_obligationsAndStandards_enlargement && (
				<>
					<RadioInput
						name={certificateLocationInput.name}
						label={certificateLocationInput.label}
						value={certificateLocationInput.value}
						list={radioSelectionYesNo}
						onChange={onSelectCertificateLocationInput}
					/>

					{displayWarningSection(
						pageAssets?.request_form_obligationsAndStandards_info_text
					)}
				</>
			)}

			{requestAboutInput.value ===
				pageAssets?.request_form_select_obligationsAndStandards_renovations && (
				<TextAreaInput
					id={plannedWorksAndCostInput.name}
					label={plannedWorksAndCostInput.label}
					value={plannedWorksAndCostInput.value}
					maxCharacters={150}
					onChange={onSelectPlannedWorksAndCostInput}
				/>
			)}
		</>
	)
}

export default ObligationsAndStandardsForm
