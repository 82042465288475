import React, { FC, useState, useRef, useContext } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import Collapse from '@components/ui/collapse'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { chevronDownBlueIcon2 } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import {
	AppStateContext,
	AppContextProps
} from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import Text from '@components/ui/text'
import { Article } from '@services/models/articles.model'
import Loader from '../loader'
import { navigate } from '@components/ui/link'

type ClassType = {
	root: string
	container: string
	sectionTitle: string
	accordion: string
	subtitle: string
	alert: string
	sectionTitleCollapse: string
	sectionTitleCollapseTitle: string
	chevronDownIcon: string
	noTranslate: string
}

const classes: ClassType = makeClasses({
	root: {
		a: {
			textDecoration: 'none',
			color: Colors.secondary,
			'&:hover': {
				textDecoration: 'underline'
			}
		},
		[Breakpoints.maxWidth('xl')]: {
			background: Colors.lightBlueTransparent50
		}
	},
	container: {
		maxWidth: '350px',
		width: '100%',
		height: '100vh',
		background: Colors.greyIron,
		padding: '40px',
		[Breakpoints.maxWidth('xl')]: {
			maxWidth: '100%',
			height: 'auto',
			background: 'none',
			padding: 0,
			'&.active': {
				padding: '20px',
				'.section-title-collapse': {
					padding: 0
				},
				'.collapse-content': {
					display: 'block'
				},
				'.text': {
					p: {
						display: 'block',
						marginTop: '5px'
					}
				},
				'.icon-collapse': {
					transform: 'rotate(-90deg)'
				}
			}
		}
	},
	sectionTitle: {
		margin: 0,
		fontSize: '30px',
		color: Colors.primary,
		lineHeight: '36px',
		fontWeight: 600,
		[Breakpoints.maxWidth('xl')]: {
			display: 'none'
		}
	},
	subtitle: {
		display: 'flex',
		fontSize: '20px',
		fontWeight: 500,
		marginTop: '10px'
	},
	accordion: {
		marginTop: '30px',
		'& > div': {
			borderBottom: `1px solid ${Colors.blackTransparent10}`,
			'&:last-child': {
				borderBottom: 0
			}
		},
		[Breakpoints.maxWidth('xl')]: {
			'&.collapse-content': {
				display: 'none'
			}
		}
	},
	alert: {
		border: `2px solid ${Colors.lightBlue}`,
		background: Colors.lightBlueTransparent20,
		padding: '20px',
		fontSize: '16px',
		lineHeight: '1.62em',
		p: {
			margin: 0
		},
		[Breakpoints.maxWidth('xl')]: {
			display: 'none'
		}
	},
	sectionTitleCollapse: {
		display: 'none',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		padding: '20px',
		p: {
			display: 'none',
			margin: 0
		},
		'.text': {
			paddingRight: '15px',
			'& p:first-child': {
				display: 'block',
				marginTop: 0
			}
		},
		'&:hover': {
			cursor: 'pointer'
		},
		[Breakpoints.maxWidth('xl')]: {
			display: 'flex'
		}
	},
	sectionTitleCollapseTitle: {
		fontWeight: 500
	},
	chevronDownIcon: {
		width: '100%',
		maxWidth: '20px',
		height: '20px',
		transform: 'rotate(90deg)'
	},
	noTranslate: {
		textTransform: 'uppercase',
		fontSize: '12px',
		lineHeight: '14px',
		fontWeight: 600
	}
})

type Props = {
	containerClass?: string
	isConfirmationPage?: boolean
	isLoading: boolean
	articles: Article[]
	isOtherRequestPage?: boolean
	requestWithoutInformationBank?: boolean
}

const InformationBank: FC<Props> = ({
	containerClass,
	articles,
	isLoading,
	isConfirmationPage,
	isOtherRequestPage,
	requestWithoutInformationBank = true
}) => {
	const { pageData, language } = useContext<AppContextProps>(AppStateContext)

	const [open, setOpen] = useState<boolean>(false)

	const collapseRef = useRef<HTMLDivElement>(null)

	const onCollapse = () => () => {
		if (collapseRef.current) {
			const isOpen: boolean = !open

			setOpen(isOpen)

			if (isOpen) {
				return collapseRef.current.classList.add('active')
			}

			return collapseRef.current.classList.remove('active')
		}
	}

	const doCollapseTitle = (article: Article) => (
		<>
			{formatStrapiText(article.title)}
			{!article.language
				.toLowerCase()
				.includes(String(language).substring(0, 2)) && (
				<div className={classes.noTranslate}>
					{formatStrapiText(
						pageData?.assets?.information_bank_section_not_translated
					)}
				</div>
			)}
		</>
	)

	const onNavigateToArticle = (article: Article) => () =>
		// navigate(article.link, { state: article })
		window.open(article.link, '_blank', 'noreferrer')

	return (
		<div className={classes.root}>
			{!isConfirmationPage &&
				!isOtherRequestPage &&
				requestWithoutInformationBank && (
					<div className={classes.alert}>
						<p>
							{formatStrapiText(
								pageData?.assets?.information_bank_section_title
							)}
						</p>
						<Text
							content={pageData?.assets?.information_bank_section_sub_title}
						/>
					</div>
				)}
			<div
				className={joinClasses([classes.container, containerClass || ''])}
				ref={collapseRef}
			>
				<h3 className={classes.sectionTitle}>
					{formatStrapiText(pageData?.assets?.information_bank_title)}
					<small className={classes.subtitle}>
						{formatStrapiText(
							pageData?.assets?.information_bank_related_article
						)}
					</small>
				</h3>
				<div
					className={joinClasses([
						classes.sectionTitleCollapse,
						'section-title-collapse'
					])}
					onClick={onCollapse()}
				>
					<div className="text">
						{!isConfirmationPage && (
							<>
								<p className={classes.sectionTitleCollapseTitle}>
									{formatStrapiText(
										pageData?.assets?.information_bank_section_title
									)}
								</p>
								<Text
									content={
										pageData?.assets?.information_bank_section_sub_title_mobile
									}
								/>
							</>
						)}
						{isConfirmationPage && (
							<p className={classes.sectionTitleCollapseTitle}>
								{formatStrapiText(pageData?.assets?.information_bank_title)}
							</p>
						)}
					</div>
					<Icon
						src={chevronDownBlueIcon2}
						className={joinClasses([classes.chevronDownIcon, 'icon-collapse'])}
					/>
				</div>
				<div className={joinClasses([classes.accordion, 'collapse-content'])}>
					{isLoading && <Loader text={pageData?.assets?.loading} />}

					{!isLoading && articles.length === 0 && (
						<>{formatStrapiText(pageData?.assets?.articles_notFound)}</>
					)}

					{!isLoading &&
						articles.map((article: Article, key: number) => (
							<Collapse
								key={article.id}
								id={key}
								buttonText={doCollapseTitle(article)}
								content={article.description}
								onClick={onNavigateToArticle(article)}
								linkText={pageData?.assets?.information_bank_read_article}
							/>
						))}
				</div>
			</div>
		</div>
	)
}

export default InformationBank
