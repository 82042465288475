import React, { FC, RefObject, useEffect, useRef, useState } from 'react'

import requestForm from '@components/requestForms/request.form'
import config from '@utils/config'
import Error from '@components/requestForms/inputs/error.input'
import RequiredIcon from '@components/ui/requiredIcon'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import RadioInput from '@components/requestForms/inputs/radio.input'
import { useAppDispatch } from '@services/store'
import {
	populateRequestConfigsState,
	populateRequestModelState,
	resetRequestConfigsState
} from '@services/store/request'
import { Request } from 'typings/shared'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import {
	potholesDamagedPavement,
	potholesStreet,
	potholesSaggingSewerCover
} from '@images/icons'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { REQUESTS_IDS, REQUEST_CONFIGS_KEYS } from '@services/constants'

type Classes = {
	imgCheckboxlabelError: string
	imgCheckboxContainer: string
	imgCheckboxItem: string
	imgCheckboxItemTitle: string
	imgCheckboxItemDesc: string
	imgCheckboxItemChoice: string
	imgCheckboxItemImg: string
	imgCheckboxItemContent: string
}

const classes: Classes = makeClasses({
	imgCheckboxlabelError: {
		color: Colors.errorRed
	},
	imgCheckboxContainer: {
		display: 'flex',
		alignItem: 'center',
		justifyContent: 'space-between',
		maxWidth: '100%',
		width: '100%',
		marginBottom: '30px',
		[Breakpoints.maxWidth('xs')]: {
			flexDirection: 'column',
			justifyContent: 'flex-start'
		}
	},
	imgCheckboxItem: {
		maxWidth: '32%',
		width: '100%',
		[Breakpoints.maxWidth('xs')]: {
			display: 'flex',
			maxWidth: '100%'
		}
	},
	imgCheckboxItemTitle: {
		fontWeight: 'bold',
		lineHeight: '1.62em'
	},
	imgCheckboxItemDesc: {
		fontSize: '14px',
		lineHeight: '18px',
		color: Colors.darkGrey
	},
	imgCheckboxItemChoice: {
		width: '100%',
		height: 'auto',
		border: `4px solid ${Colors.white}`,
		cursor: 'pointer',
		'&.selected': {
			borderColor: Colors.secondary,
			'&:hover': {
				borderColor: Colors.secondary
			}
		},
		'&:hover': {
			borderColor: Colors.primaryConnected
		}
	},
	imgCheckboxItemImg: {
		[Breakpoints.maxWidth('xs')]: {
			maxWidth: '40%',
			width: '100%'
		}
	},
	imgCheckboxItemContent: {
		[Breakpoints.maxWidth('xs')]: {
			maxWidth: '60%',
			width: '100%',
			paddingLeft: '10px',
			marginBottom: '10px'
		}
	}
})

const endPos: number = -1

const PotHoleForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		requests,
		radioSelectionYesNo,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection,
		dispatchPopulateArticlesRequestId
	} = requestForm()

	const dispatch = useAppDispatch()

	const { potholesDamagedPavement: damagedPavement, requestTypeListIncludes } =
		config.request.create.potHoleStreet

	const [notificationText, setNotificationText] = useState<string>('')
	const [requestTypeList, setRequestTypeList] = useState<Request[]>([])
	const [requestTypeSelected, setRequestTypeSelected] = useState<string>('')
	const [requestTypeInput, setRequestTypeInput] = useState<RequestInputs>({
		name: 'requestType',
		label: pageAssets?.request_form_potholeStreet_requestType_label,
		labelKey: 'request_form_potholeStreet_requestType_label',
		value: '',
		required: true,
		ref: useRef<HTMLImageElement>(null)
	})
	const [streetQuestionInput, setStreetQuestionInput] = useState<RequestInputs>(
		{
			name: 'streetQuestion',
			label: pageAssets?.request_form_potholeStreet_streetQuestion_label,
			labelKey: 'request_form_potholeStreet_streetQuestion_label',
			value: '',
			valueKey: '',
			required: false
		}
	)
	const [typeOfWorkInput, setTypeOfWorkInput] = useState<RequestInputs>({
		name: 'typeOfWork',
		label: pageAssets?.request_form_potholeStreet_typeOfWork_label,
		labelKey: 'request_form_potholeStreet_typeOfWork_label',
		value: '',
		valueKey: '',
		required: false
	})
	const [pavementInput, setPavementInput] = useState<RequestInputs>({
		name: 'pavement',
		label: pageAssets?.request_form_potholeStreet_pavement_label,
		labelKey: 'request_form_potholeStreet_pavement_label',
		value: '',
		valueKey: '',
		required: false
	})
	const [securityIssueInput, setSecurityIssueInput] = useState<RequestInputs>({
		name: 'securityIssue',
		label: pageAssets?.request_form_potholeStreet_securityIssue_label,
		labelKey: 'request_form_potholeStreet_securityIssue_label',
		value: '',
		valueKey: '',
		required: false
	})

	const streetQuestionList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_potholeStreet_newStreet,
			value: pageAssets?.select_potholeStreet_newStreet,
			key: 'select_potholeStreet_newStreet'
		},
		{
			label: pageAssets?.select_potholeStreet_oldStreet,
			value: pageAssets?.select_potholeStreet_oldStreet,
			key: 'select_potholeStreet_oldStreet'
		},
		{
			label: pageAssets?.select_potholeStreet_streetRecentWorks,
			value: pageAssets?.select_potholeStreet_streetRecentWorks,
			key: 'select_potholeStreet_streetRecentWorks'
		},
		{
			label: pageAssets?.select_potholeStreet_notApplicable,
			value: pageAssets?.select_potholeStreet_notApplicable,
			key: 'select_potholeStreet_notApplicable'
		}
	]

	const typeOfWorkList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_potholeStreet_minorWork,
			value: pageAssets?.select_potholeStreet_minorWork,
			key: 'select_potholeStreet_minorWork'
		},
		{
			label: pageAssets?.select_potholeStreet_majorWork,
			value: pageAssets?.select_potholeStreet_majorWork,
			key: 'select_potholeStreet_majorWork'
		},
		{
			label: pageAssets?.request_form_select_dont_know,
			value: pageAssets?.request_form_select_dont_know,
			key: 'request_form_select_dont_know'
		}
	]

	const pavementList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_potholeStreet_gravel,
			value: pageAssets?.select_potholeStreet_gravel,
			key: 'select_potholeStreet_gravel'
		},
		{
			label: pageAssets?.select_potholeStreet_asphalt,
			value: pageAssets?.select_potholeStreet_asphalt,
			key: 'select_potholeStreet_asphalt'
		}
	]

	const onSelectRequestTypeInput = (
		title: string,
		value: string,
		name: string
	) => {
		setTypeOfWorkInput({ ...typeOfWorkInput, value: '', valueKey: '' })
		setSecurityIssueInput({ ...securityIssueInput, value: '', valueKey: '' })
		onResetConfigState(true)

		if (requestTypeInput.value === value) {
			setRequestTypeInput({ ...requestTypeInput, value: '' })
			setRequestTypeSelected('')
			setNotificationText('')

			dispatch(populateRequestModelState({ title: '', typeId: '' }))

			return
		}

		const pageNotification: string = name.includes(damagedPavement)
			? pageAssets?.request_form_potholeStreet_notification_pavement_text
			: pageAssets?.request_form_potholeStreet_notification_text

		setNotificationText(pageNotification)
		setRequestTypeInput({ ...requestTypeInput, value })
		setRequestTypeSelected(name)

		onFixError(UpdateRequestInputsErrorsArray(errors, requestTypeInput.name))

		if (value === REQUESTS_IDS.saggingSewerCover) {
			dispatch(
				populateRequestConfigsState({
					coveredSewerIntervention: Number(
						REQUEST_CONFIGS_KEYS.sewerIntervention.collapsed
					)
				})
			)
		}

		dispatch(populateRequestModelState({ title, typeId: value }))
	}

	const onSelectStreetQuestionInput = (value: string, valueKey: string) => {
		setTypeOfWorkInput({ ...typeOfWorkInput, value: '', valueKey: '' })
		setStreetQuestionInput({ ...streetQuestionInput, value, valueKey })
	}

	const onSelectTypeOfWorkInput = (value: string, valueKey: string) => {
		setTypeOfWorkInput({ ...typeOfWorkInput, value, valueKey })
	}

	const onSelectPavementInput = (value: string, valueKey: string) => {
		setPavementInput({ ...pavementInput, value, valueKey })
	}

	const onSelectSecurityIssueInput = (value: string, valueKey: string) => {
		setSecurityIssueInput({ ...securityIssueInput, value, valueKey })

		if (value === pageAssets?.request_form_select_yes) {
			onResetConfigState()
			return
		}

		onResetConfigState(true)
	}

	const getPotHolesImage = (index: string): string => {
		const potHolesImg: { [key: string]: string } = {
			potholes_street: potholesStreet,
			potholes_damagedPavement: potholesDamagedPavement,
			potholes_saggingSewerCover: potholesSaggingSewerCover
		}

		return potHolesImg[index.slice(0, endPos)]
	}

	const onResetConfigState = (removeKeyFromConfig: boolean = false) => {
		if (removeKeyFromConfig) {
			dispatch(resetRequestConfigsState())

			return
		}

		dispatch(populateRequestConfigsState({ immediateAttention: true }))
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, requestTypeInput))
	}, [requestTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, streetQuestionInput))
	}, [streetQuestionInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, pavementInput))
	}, [pavementInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, securityIssueInput))
	}, [securityIssueInput])

	useEffect(() => {
		const listRequestId: string[] = []
		const potHoleList: Request[] = requests.filter((r: Request) => {
			if (r.name.includes(requestTypeListIncludes)) {
				listRequestId.push(r.requestId)

				return r
			}
		})

		potHoleList.sort((listA, listB) => {
			const orderA: string = listA.name.slice(endPos)
			const orderB: string = listB.name.slice(endPos)

			return orderA.localeCompare(orderB)
		})

		setRequestTypeList(potHoleList)
		dispatchPopulateArticlesRequestId(listRequestId)
	}, [])

	return (
		<>
			<div>
				<label
					className={
						errors.includes(requestTypeInput.name)
							? classes.imgCheckboxlabelError
							: ''
					}
				>
					{requestTypeInput.label}
					<RequiredIcon />
					<Error hasError={errors.includes(requestTypeInput.name)} />
				</label>
				<div className={classes.imgCheckboxContainer}>
					{requestTypeList.map((requestItem: Request, index: number) => (
						<div className={classes.imgCheckboxItem} key={index}>
							<div className={classes.imgCheckboxItemImg}>
								<img
									className={joinClasses([
										classes.imgCheckboxItemChoice,
										requestTypeInput.value === requestItem.requestId
											? 'selected'
											: ''
									])}
									src={getPotHolesImage(requestItem.name)}
									onClick={(e) => {
										e.preventDefault()
										onSelectRequestTypeInput(
											requestItem.title,
											requestItem.requestId,
											requestItem.name
										)
									}}
									{...(index === 0
										? {
												ref: requestTypeInput.ref as RefObject<HTMLImageElement>
										  }
										: {})}
									tabIndex={1}
								/>
							</div>
							<div className={classes.imgCheckboxItemContent}>
								<p className={classes.imgCheckboxItemTitle}>
									{
										pageAssets?.request_form_potholeStreet_requestType_photo_label
									}{' '}
									{index + 1}
								</p>
								<p className={classes.imgCheckboxItemDesc}>
									{requestItem.title}
									{requestItem.description && <> ({requestItem.description})</>}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
			<SelectInput
				id={streetQuestionInput.name}
				label={streetQuestionInput.label}
				value={streetQuestionInput.value}
				list={streetQuestionList}
				onChange={onSelectStreetQuestionInput}
			/>
			{requestTypeSelected.includes(damagedPavement) &&
				streetQuestionInput.value ===
					pageAssets?.select_potholeStreet_streetRecentWorks && (
					<SelectInput
						id={typeOfWorkInput.name}
						label={typeOfWorkInput.label}
						value={typeOfWorkInput.value}
						list={typeOfWorkList}
						onChange={onSelectTypeOfWorkInput}
					/>
				)}
			<SelectInput
				id={pavementInput.name}
				label={pavementInput.label}
				value={pavementInput.value}
				list={pavementList}
				onChange={onSelectPavementInput}
			/>
			{requestTypeSelected.includes(damagedPavement) && (
				<RadioInput
					name={securityIssueInput.name}
					label={securityIssueInput.label}
					value={securityIssueInput.value}
					list={radioSelectionYesNo}
					onChange={onSelectSecurityIssueInput}
				/>
			)}
			{notificationText !== '' && (
				<>{displayWarningSection(notificationText)}</>
			)}
		</>
	)
}

export default PotHoleForm
