import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RadioList,
	RequestFormProps,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import RadioInput from '@components/requestForms/inputs/radio.input'

const PanelsAndSignalingForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		radioListYesNo,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection
	} = requestForm()

	const defaultInputsNoValues: {
		value: string
		valueKey: string
		labelKey: string
	} = {
		value: REQUEST_CONFIGS_KEYS.no,
		valueKey: 'request_form_select_no',
		labelKey: ''
	}

	const [notificationText, setNotificationText] = useState<string>('')
	const [showDroppedStopSignQuestion, setShowDroppedStopSignQuestion] =
		useState<boolean>(false)
	const [signalingInterventionInput, setSignalingInterventionInput] =
		useState<RequestInputs>({
			name: 'signalingIntervention',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_panelsAndSignaling_signalingIntervention_label,
			labelKey: 'request_form_panelsAndSignaling_signalingIntervention_label',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})
	const [signalingTypeInput, setSignalingTypeInput] = useState<RequestInputs>({
		name: 'signalingType',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_panelsAndSignaling_signalingType_label,
		labelKey: 'request_form_panelsAndSignaling_signalingType_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})
	const [droppedStopSignInput, setDroppedStopSignInput] =
		useState<RequestInputs>({
			name: 'droppedStopSign',
			...defaultInputsNoValues,
			label: pageAssets?.request_form_panelsAndSignaling_droppedStopSign_label,
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})
	const [immediateAttentionInput, setImmediateAttentionInput] =
		useState<RequestInputs>({
			name: 'immediateAttention',
			value: '',
			valueKey: '',
			label: '',
			labelKey: '',
			required: false
		})
	const [harshSignageInput, setHarshSignageInput] = useState<RequestInputs>({
		name: 'harshSignage',
		...defaultInputsNoValues,
		label: pageAssets?.request_form_panelsAndSignaling_harshSignage_label,
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const signalingInterventionList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_panelsAndSignaling_add,
			value: REQUEST_CONFIGS_KEYS.signalingIntervention.add,
			key: 'select_panelsAndSignaling_add'
		},
		{
			label: pageAssets?.select_panelsAndSignaling_repair,
			value: REQUEST_CONFIGS_KEYS.signalingIntervention.repair,
			key: 'select_panelsAndSignaling_repair'
		},
		{
			label: pageAssets?.select_panelsAndSignaling_modification,
			value: REQUEST_CONFIGS_KEYS.signalingIntervention.modification,
			key: 'select_panelsAndSignaling_modification'
		},
		{
			label: pageAssets?.select_panelsAndSignaling_obstruction,
			value: REQUEST_CONFIGS_KEYS.signalingIntervention.obstruction,
			key: 'select_panelsAndSignaling_obstruction'
		}
	]

	const signalingTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_panelsAndSignaling_traffic,
			value: REQUEST_CONFIGS_KEYS.signalingType.traffic,
			key: 'select_panelsAndSignaling_traffic'
		},
		{
			label: pageAssets?.select_panelsAndSignaling_parks,
			value: REQUEST_CONFIGS_KEYS.signalingType.parks,
			key: 'select_panelsAndSignaling_parks'
		},
		{
			label: pageAssets?.select_panelsAndSignaling_worksite,
			value: REQUEST_CONFIGS_KEYS.signalingType.worksite,
			key: 'select_panelsAndSignaling_worksite'
		},
		{
			label: pageAssets?.select_panelsAndSignaling_other,
			value: REQUEST_CONFIGS_KEYS.signalingType.other,
			key: 'select_panelsAndSignaling_other'
		}
	]

	const harshSignageList: RadioList[] = [
		...radioListYesNo,
		{
			label: pageAssets?.request_form_select_dont_know,
			value: REQUEST_CONFIGS_KEYS.unknown,
			key: 'request_form_select_dont_know'
		}
	]

	const onSelectSignalingInterventionInput = (
		value: string,
		valueKey: string
	) => {
		setSignalingInterventionInput({
			...signalingInterventionInput,
			value,
			valueKey
		})
		onFixError(
			UpdateRequestInputsErrorsArray(errors, signalingInterventionInput.name)
		)

		switch (value) {
			case '':
				setNotificationText('')
				break
			case REQUEST_CONFIGS_KEYS.signalingIntervention.add:
				setNotificationText(
					pageAssets?.request_form_panelsAndSignaling_info_add_text
				)
				break
			default:
				setNotificationText(
					pageAssets?.request_form_panelsAndSignaling_info_text
				)
				break
		}

		if (value === REQUEST_CONFIGS_KEYS.signalingIntervention.repair) {
			setHarshSignageInput({
				...harshSignageInput,
				value: '',
				valueKey: '',
				labelKey: 'request_form_panelsAndSignaling_harshSignage_label'
			})

			return
		}

		setHarshSignageInput({ ...harshSignageInput, ...defaultInputsNoValues })
	}

	const onSelectSignalingTypeInput = (value: string, valueKey: string) => {
		setSignalingTypeInput({ ...signalingTypeInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, signalingTypeInput.name))
	}

	const onSelectDroppedStopSignInput = (value: string, valueKey: string) => {
		setDroppedStopSignInput({ ...droppedStopSignInput, value, valueKey })
		onFixError(
			UpdateRequestInputsErrorsArray(errors, droppedStopSignInput.name)
		)

		if (value === REQUEST_CONFIGS_KEYS.yes) {
			setImmediateAttentionInput({
				...immediateAttentionInput,
				value: 'true',
				required: true
			})

			return
		}

		setImmediateAttentionInput({
			...immediateAttentionInput,
			value: '',
			required: false
		})
	}

	const onSelectHarshSignageInput = (value: string, valueKey: string) => {
		setHarshSignageInput({ ...harshSignageInput, value, valueKey })
	}

	const doShowDroppedStopSignInput = (): boolean => {
		return (
			(signalingInterventionInput.value ===
				REQUEST_CONFIGS_KEYS.signalingIntervention.repair ||
				signalingInterventionInput.value ===
					REQUEST_CONFIGS_KEYS.signalingIntervention.obstruction) &&
			signalingTypeInput.value !== REQUEST_CONFIGS_KEYS.signalingType.parks &&
			signalingTypeInput.value !== ''
		)
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, signalingInterventionInput))
		setShowDroppedStopSignQuestion(doShowDroppedStopSignInput())
	}, [signalingInterventionInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, signalingTypeInput))
		setShowDroppedStopSignQuestion(doShowDroppedStopSignInput())
	}, [signalingTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, droppedStopSignInput))
	}, [droppedStopSignInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, harshSignageInput))
	}, [harshSignageInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, immediateAttentionInput))
	}, [immediateAttentionInput])

	useEffect(() => {
		if (showDroppedStopSignQuestion) {
			setDroppedStopSignInput({
				...droppedStopSignInput,
				value: '',
				valueKey: '',
				labelKey: 'request_form_panelsAndSignaling_droppedStopSign_label'
			})

			return
		}

		setDroppedStopSignInput({
			...droppedStopSignInput,
			...defaultInputsNoValues
		})
		setImmediateAttentionInput({
			...immediateAttentionInput,
			value: '',
			required: false
		})
	}, [showDroppedStopSignQuestion])

	return (
		<>
			<SelectInput
				id={signalingInterventionInput.name}
				label={signalingInterventionInput.label}
				value={signalingInterventionInput.value}
				list={signalingInterventionList}
				onChange={onSelectSignalingInterventionInput}
				required
				ref={signalingInterventionInput.ref as RefObject<HTMLSelectElement>}
			/>

			<SelectInput
				id={signalingTypeInput.name}
				label={signalingTypeInput.label}
				value={signalingTypeInput.value}
				list={signalingTypeList}
				onChange={onSelectSignalingTypeInput}
				required
				ref={signalingTypeInput.ref as RefObject<HTMLSelectElement>}
			/>

			{showDroppedStopSignQuestion && (
				<RadioInput
					name={droppedStopSignInput.name}
					label={droppedStopSignInput.label}
					value={droppedStopSignInput.value}
					list={radioListYesNo}
					onChange={onSelectDroppedStopSignInput}
					required
					ref={droppedStopSignInput.ref as RefObject<HTMLInputElement>}
				/>
			)}

			{signalingInterventionInput.value ===
				REQUEST_CONFIGS_KEYS.signalingIntervention.repair && (
				<RadioInput
					name={harshSignageInput.name}
					label={harshSignageInput.label}
					value={harshSignageInput.value}
					list={harshSignageList}
					onChange={onSelectHarshSignageInput}
					required
					ref={harshSignageInput.ref as RefObject<HTMLInputElement>}
				/>
			)}

			{notificationText !== '' && displayWarningSection(notificationText)}
		</>
	)
}

export default PanelsAndSignalingForm
